<template>
  <el-main>
    <div class="search">
      <div class="left">
        <span>隔离人员反馈</span>
      </div>
      <div class="right">
        <el-input v-model="equipment_no" placeholder="请输入搜索..."></el-input>
        <el-button type="primary">搜索</el-button>
        <el-button @click="output">导出</el-button>
        <el-button>导入</el-button>
      </div>
    </div>
    <div class="list">
      <div class="item" v-for="(item,index) in list" :key="index">
        <div class="left">
          <el-button type="primary" size="mini">243243</el-button>
          <span>{{item.content}}</span>
        </div>
        <div class="right" v-for="(images,y) in item.imagess" :key="y">
          <el-image :src="images"></el-image>
        </div>
      </div>
    </div>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      page:1,
      rows:10,
      list:[]
    };
  },
  created () {
    this.getList();
  },
  methods: {
    getList(){
      this.$axios.post(this.$api.category.list,{
        page:this.page,
        rows:this.rows
      }).then(res=>{
        if(res.code == 0){
          this.list = res.result.list;
        }else{
          this.$message.error(res.msg)
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.el-main {
  color: #333;
  background: #fff;
  height: 100%;
  .search {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .left {
      font-weight: bold;
      font-size: 18px;
      span:nth-child(2) {
        margin-left: 4px;
        color: #1e92ff;
      }
    }
    .right {
      .el-input {
        width: 270px;
        margin-right: 20px;
        /deep/ .el-input__inner {
          border-radius: 19px;
          margin-right: 20px;
        }
      }

      .el-button {
        border-radius: 19px;
      }
      .el-button--default {
        border-color: #409EFF;
      }
    }
  }
  .list {
    .item {
      margin-bottom: 20px;
      height: 120px;
      border: 1px solid #E0E0E0;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px 0 20px;
      .left {
        .el-button {
          border-radius: 14px;
          margin-right: 12px;
        }
      }
      .right {
        .el-image {
          margin-right: 10px;
          width: 72px;
          height: 72px;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>